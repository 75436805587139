// Package Imports
import React from 'react'
import { Button } from 'antd';

// Project Imports
import getAPiUrl from '../../../utils/A8Utils/Methods/getApiUrl';

const GridDownloadButton = (props) => {
    const { component, submissionData } = props;

    let formDownloadFields = {
        downloadExcel: component.showDownloadExcel,
        downloadUrl: component.downloadUrl,
        downloadApiUrlParamsList: component.downloadApiUrlParamsList,
    }

    const downloadApiCallHandler = () => {

        let apiUrlParams = {}
        formDownloadFields.downloadApiUrlParamsList?.forEach((item) => {
            apiUrlParams[item.downloadApiUrlParameter] = submissionData[item.downloadApiUrlTargetKey]
        })

        if (formDownloadFields.downloadUrl) {
            let url = getAPiUrl(formDownloadFields.downloadUrl, apiUrlParams)

            fetch(url)
                .then((response) => {
                    return response.blob().then(blob => ({ blob, filename: response.headers.get('filename') }));
                })
                .then(({ blob, filename }) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {

                })
        }

    }

    return (
        <>
            <Button className='grid-button' type="primary" size='large' onClick={() => downloadApiCallHandler()} >Download Details</Button>
        </>
    )
}

export default GridDownloadButton