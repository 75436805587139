import getAPiUrl from "./Methods/getApiUrl";
import getColumnElementValue from "./Methods/getColumnElementValue";
import { fileExtensions } from "./Constants/fileExtensions"
import { fileTypes } from "./Constants/fileTypes"
import { messages } from "./Constants/messages"
import { regularExpressions } from "./Constants/regularExpressions"
import { formBuilderFieldNames } from "./Constants/formBuilderFieldNames"
import { labels } from "./Constants/labels"

const A8Utils = {
    getColumnElementValue,
    getAPiUrl,
    fileExtensions,
    fileTypes,
    messages,
    regularExpressions,
    formBuilderFieldNames,
    labels
}
export default A8Utils;