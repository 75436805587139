// Package imports
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Col, Row } from 'antd';

// Project imports
import A8Utils from '../../../utils/A8Utils';
import "../index.css"


const GridActionButtonsCustomComp = (props) => {
    const { component, value, isBuilder, thiz } = props;
    const [ready, setReady] = useState(false);

    const [actionButtonList] = useState(component.actionButtonsList)
    const [formApiFields] = useState({
        apiUrl: component.apiUrl,
        apiUrlParamsList: component.apiUrlParamsList,
        propertiesList: component.propertiesList,
        apiUrlMethod: component.apiUrlMethod
    })



    function apiCallhandler(btnItem) {
        if (isBuilder) {
            return
        }

        let apiUrlParams = {};

        formApiFields.apiUrlParamsList.forEach((item) => {
            apiUrlParams[item.apiUrlParameter] = A8Utils.getColumnElementValue(value, item.apiUrlTargetKey)
        });
        let url = A8Utils.getAPiUrl(formApiFields.apiUrl, apiUrlParams)

        let apiBody = {
            taskKey: value.taskKey,
            action: btnItem.action,
            variables: {},
            submissionData: { ...value },
            formIoData: value.formIoData
        }


        formApiFields.propertiesList.forEach((item) => {
            apiBody.variables[item.propertyKey] = A8Utils.getColumnElementValue(value, item.propertyValue)
        });
        fetch(url, {
            method: formApiFields.apiUrlMethod || 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiBody)
        })
            .then((res) => res.json())
            .then((json) => {
                thiz.emit('submitButton', {
                    state: thiz.component.state || 'submitted',
                    component: thiz.component,
                    instance: thiz
                });
            })
            .catch((error) => {
                console.error(error);
            })
        console.log("url>>>>", url)
    }
    useEffect(() => {
        let state = true;
        if (isBuilder) {
            state = true;
        }
        if (value['oid']) {
            state = true;
        }
        setReady(state)
        console.log('value  useEffect', state, value['oid'])
    }, [value['oid']])
    if (!ready) {
        return <>Loadding!!!!</>
    }
    return (
        <>
            <Row justify="end" align="middle" className='my-3'>
                {
                    actionButtonList?.map((item) => {
                        switch (item.buttonType) {
                            case A8Utils.formBuilderFieldNames.primary:
                                return (
                                    <Col className='mx-2' key={item.name}>
                                        <Button className='btn-positive' onClick={() => { apiCallhandler(item) }} size="large" type={`${item.buttonType}`} >{item.buttonLabel}</Button>
                                    </Col>
                                )
                            case A8Utils.formBuilderFieldNames.danger:
                                return (
                                    <Col className='mx-2' key={item.name}>
                                        <Button className='btn-negative' onClick={() => { apiCallhandler(item) }} size="large" danger>{item.buttonLabel}</Button>
                                    </Col>
                                )
                            default:
                                return (
                                    <Col className='mx-2' key={item.name}>
                                        <Button className='btn-positive' onClick={() => { apiCallhandler(item) }} size="large" type={`${item.buttonType}`} >{item.buttonLabel}</Button>
                                    </Col>
                                )
                        }
                    })
                }
            </Row>
        </>
    )
}

export default GridActionButtonsCustomComp