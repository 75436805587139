const one = "1"
const two = "2"
const upload = 'Upload'
const adjustment = 'Adjustment'
const customerName = "Customer Name"
const agencyInvoice = 'Agency Invoice'
const uploadInvoice = 'Upload Invoice'
const downloadInvoice = 'Download Invoice'
const uploadComponentTitle = 'Click or drag file to this area to upload'
const uploadComponentSubTitle = 'Supported File Extension'

const labels = {
    downloadInvoice,
    uploadInvoice,
    agencyInvoice,
    one,
    two,
    upload,
    uploadComponentTitle,
    uploadComponentSubTitle,
    customerName,
    adjustment
}

export { labels }