//Package Imports
import React, { useEffect, useState } from 'react'
import { Button, Upload, Table } from 'antd';

//Project Imports
import getAPiUrl from '../../../utils/A8Utils/Methods/getApiUrl';
import getColumnElementValue from '../../../utils/A8Utils/Methods/getColumnElementValue';

const GridTimeline = (props) => {

    const { component, submissionData, showTimeLineComponent } = props;

    const [timeLinedata, setTimeLineData] = useState([])

    useEffect(() => {
        if (showTimeLineComponent) {
            showTimeLineApiCallHandler()
        }
    }, [showTimeLineComponent])

    let formTimelineFields = {
        showTimeline: component.showTimeline,
        timelineUrl: component.timelineUrl,
        timelineColumnList: component.timelineColumnList,
        timelineResponseDataKey: component.timelineResponseDataKey,
        timelineApiUrlParamsList: component.timelineApiUrlParamsList,
    }

    function makeTimelineColumnListItem(item, records) {
        switch (item.timelineColumnKey) {
            default:
                return (
                    <>{getColumnElementValue(records, item.timelineColumnKey)}</>
                )
        }
    }

    const columns = component.timelineColumnList.map((item) => (
        {
            ...item,
            title: item.timelineColumnName,
            dataIndex: item.timelineColumnKey,
            key: item.timelineColumnKey,
            render: (_, records) => (
                <>
                    {
                        makeTimelineColumnListItem(item, records)
                    }
                </>
            )
        }
    ))

    function showTimeLineApiCallHandler() {
        let apiUrlParams = {}
        formTimelineFields.timelineApiUrlParamsList?.forEach((item) => {
            apiUrlParams[item.timelineApiUrlParameter] = submissionData[item.timelineApiUrlTargetKey]
        })

        if (formTimelineFields.timelineUrl) {
            let url = getAPiUrl(formTimelineFields.timelineUrl, apiUrlParams)

            fetch(url)
                .then((res) => res.json())
                .then((json) => {
                    setTimeLineData(getColumnElementValue(json, formTimelineFields.timelineResponseDataKey, "object"))
                })
                .catch((error) => {
                })
        }
    }

    return (
        <div>
            <Table dataSource={timeLinedata} columns={columns} />
        </div>
    )
}

export default GridTimeline