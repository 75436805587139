//Package Imports
import React, { useEffect, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons';
import { Form, Select, Icon, Input, Upload, Button, Modal, message } from 'antd';

//Project Imports

import FormUtils from '../utils/FormUtils';
import A8Utils from '../utils/A8Utils';


const A8AdjustmentModal = (props) => {

    const { visible, onOk, onCancel, data, addAdjustmentApiUrlParamsList, addAdjustmentUrl, submissionData, addAdjustmentApiUrlPayloadParamsList, reasonsFieldApiUrl, reasonsFieldApiUrlParamsList, reasonsFieldApiResponseDataKey } = props;

    const { TextArea } = Input;
    const { Dragger } = Upload;
    const FormItem = Form.Item;
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([])
    const [reasonOptionList, setReasonOptionList] = useState([])

    useEffect(() => {
        getReasonApiCallHandler()
    }, []);

    // const reasonOptionList = [
    //     {
    //         label: "Reason A",
    //         value: "reason_a"
    //     },
    //     {
    //         label: "Reason B",
    //         value: "reason_b"
    //     },
    //     {
    //         label: "Reason C",
    //         value: "reason_c"
    //     },
    //     {
    //         label: "Reason D",
    //         value: "reason_d"
    //     },
    // ]
    const reasonFormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 },
    };

    const adjustmentFormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 8 },
    };

    const uploadFormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 },
    };

    const remarkFormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 },
    };

    const filterOption = (input, option) => {
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };

    const resetForm = () => {
        form.resetFields()
    }

    const validateNumber = (_, value) => {
        if (!value || /^[0-9]+$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter only numbers!'));
    };

    const addAdjustmentApiCall = (url, data) => {
        fetch(url, {
            method: 'POST',
            body: data
        })
            .then(r => r.json())
            .then(data => {
                console.log("addAdjustmentApiCallHandler::::responseData", data)
                resetForm()
                onCancel()
            })
            .catch(error => console.log(error))
    }

    const addAdjustmentApiCallHandler = (values) => {
        let formData = new FormData()
        const selectedElement = data;
        selectedElement.userId = "opsuser";
        selectedElement.paymentPartyCalcId = 11936;
        selectedElement.userName = "OPS_USER";
        selectedElement.transactionId = 5390;
        selectedElement.filePath = "";

        const payloadParameterGrid = addAdjustmentApiUrlPayloadParamsList;
        console.log("payloadParameterGrid::::", payloadParameterGrid)

        let adjustmentDetailsObject = {}
        payloadParameterGrid.forEach((item) => {
            if (item.addAdjustmentApiUrlTarget === "formData") {
                if (item.addAdjustmentApiUrlPayloadParameter !== "file") {
                    adjustmentDetailsObject[item.addAdjustmentApiUrlPayloadParameter] = values[item.addAdjustmentApiUrlPayloadTargetKey]
                }
            }
            if (item.addAdjustmentApiUrlTarget === "rowData") {
                adjustmentDetailsObject[item.addAdjustmentApiUrlPayloadParameter] = selectedElement[item.addAdjustmentApiUrlPayloadTargetKey]
            }
        })

        let adjustmentDetails = new Blob(
            [JSON.stringify(adjustmentDetailsObject)],
            {
                type: "application/json",
            }
        )
        formData.append("manualAdjustmentDetail", adjustmentDetails)

        if (typeof values?.referenceFile == "object") {
            formData.append("file", values?.referenceFile)
        }

        let apiUrlParams = {}
        addAdjustmentApiUrlParamsList?.forEach((item) => {
            apiUrlParams[item.addAdjustmentApiUrlParameter] = submissionData[item.addAdjustmentApiUrlTargetKey]
        })

        let url = A8Utils.getAPiUrl(addAdjustmentUrl, apiUrlParams)

        addAdjustmentApiCall(url, formData)
    }

    const handleReasonOptionList = (data) => {
        const list = FormUtils.getColumnElementValue(data, reasonsFieldApiResponseDataKey)
        const modifiedList = list.map(item => ({
            ...item,
            label: item.reason,
            value: item.reason
        }));
        setReasonOptionList(modifiedList)
    }

    const getReasonApiCallHandler = () => {
        let apiUrlParams = {}
        reasonsFieldApiUrlParamsList?.forEach((item) => {
            if (item.reasonsFieldApiUrlParameter !== "") {
                apiUrlParams[item.reasonsFieldApiUrlParameter] = submissionData[item.reasonsFieldApiUrlTargetKey]
            }
        })
        const apiUrlParamsHasUndefined = Object.values(apiUrlParams).some(value => value === undefined);

        let url = reasonsFieldApiUrl;
        if (!apiUrlParamsHasUndefined) {
            url = A8Utils.getAPiUrl(reasonsFieldApiUrl, apiUrlParams)
        }

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                handleReasonOptionList(json)
            })
            .catch((error) => {
            })

    }

    const normFile = (e) => {
        return e.fileList[0];
    };

    const uploadComponentProps = {
        name: 'referenceFile',
        multiple: false,
        maxCount: 1,
        showUploadList: true,
        onRemove: (file) => {
            setFileList(prevFileList => {
                const index = prevFileList.indexOf(file);
                const newFileList = [...prevFileList];
                newFileList.splice(index, 1);
                return newFileList;
            });
            return true;
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
    };

    const handleSubmit = (e) => {
        form.validateFields()
            .then((values) => {
                console.log("FormValues:::", values)
                addAdjustmentApiCallHandler(values)
            })
            .catch((errorInfo) => { });
    };

    return (
        <Modal
            title="Add Adjustment"
            wrapClassName="vertical-center-modal"
            width={1000}
            visible={visible}
            onOk={handleSubmit}
            onCancel={() => {
                resetForm()
                onCancel()
            }}
        >
            <Form form={form} className="mt-3"  >
                <FormItem
                    {...adjustmentFormItemLayout}
                    label="Adjustment Amount"
                    name="adjustmentAmount"
                    rules={[{ required: true, message: 'Please Enter Adjustment Amount' }, { validator: validateNumber }]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    {...reasonFormItemLayout}
                    label="Reason"
                    name="reason"
                    rules={[{ required: true, message: 'Please Select Reason' }]}
                >
                    <Select
                        className=''
                        showSearch
                        placeholder="Select a reason"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={reasonOptionList}
                    />
                </FormItem>
                <FormItem
                    {...uploadFormItemLayout}
                    label="Reference File"
                    name="referenceFile"
                    rules={[{ required: true, message: 'Please Upload Reference File' }]}
                    getValueFromEvent={normFile}
                >
                    <Dragger {...uploadComponentProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                        </p>
                    </Dragger>
                </FormItem>
                <FormItem
                    {...remarkFormItemLayout}
                    label="Remark"
                    name="remark"
                    rules={[{ required: true, message: 'Please Enter Remark' }]}
                >
                    <TextArea
                        rows={4}
                        placeholder="maxLength is 100"
                        maxLength={100} />
                </FormItem>
            </Form>
        </Modal>
    )
}

export default A8AdjustmentModal