const submission = 'submission';
const formData = 'formData';
const invoiceData = 'invoiceData';
const text = 'text';
const input = 'input';
const download = 'download'
const upload = 'upload'
const primary = 'primary'
const danger = 'danger'

const formBuilderFieldNames = {
    submission,
    formData,
    invoiceData,
    text,
    input,
    download,
    upload,
    primary,
    danger
}

export { formBuilderFieldNames }